.svg-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke-opacity: 0.5;
}

.svg-container {
  width: 100%;
  height: 100%;
}

.line,
.line-flipped {
  fill: none;
  stroke-dasharray: 853;
  stroke-dashoffset: 853;
  animation-fill-mode: forwards;
}

.line {
  animation: line 8s ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  stroke-width: 1;
}

.line-flipped {
  animation: flipped 8s ease-in-out;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  stroke-width: 1;
}

@keyframes line {
  0% {
    stroke-dasharray: 0, 854;
    /* Initial values */
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 427, 427;
    /* Final state, tail is fully drawn, head is at the end */
    stroke-dashoffset: 0;
    /* Adjust the value based on your line length */
  }

  100% {
    stroke-dasharray: 854, 854;
    /* Final state, tail is fully drawn, head is at the end */
    stroke-dashoffset: -854;
    /* Adjust the value based on your line length */
  }
}

@keyframes flipped {
  0% {
    stroke-dasharray: 854, 854;
    stroke-dashoffset: -854;
  }

  50% {
    stroke-dasharray: 427, 427;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 0, 854;
    stroke-dashoffset: 0;
  }
}