.notification-toast {
  position: fixed;
  bottom: -100px; /* Start position off-screen */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  animation: slideInOut 4s forwards ease-in-out; /* Adjust the duration as needed */
}

@keyframes slideInOut {
  0% {
    bottom: -100px;
  }
  10% {
    bottom: 0px; /* Stay at 50% position for longer */
  }
  90% {
    bottom: 0px; /* Stay at 50% position for longer */
  }
  100% {
    bottom: -200px;
  }
}

.notification-toast.error {
  background-color: #333;
}

.notification-toast.success {
  background: var(--background-gradient);
}