:root {
  --total-time: 20s;
  --background-gradient: radial-gradient(64% 70% at 100% 0%,
      rgba(247, 214, 98, 0.8) 0%,
      rgba(247, 214, 98, 0.2) 52%,
      rgba(247, 214, 98, 0) 100%),
    linear-gradient(208deg,
      rgba(240, 66, 42, 1) 7%,
      rgba(240, 88, 42, 0.2) 43%,
      rgba(240, 101, 42, 0) 64%),
    radial-gradient(115% 123% at 0% -15%,
      rgba(231, 79, 106, 1) 1%,
      rgba(231, 79, 106, 0.2) 67%,
      rgba(231, 79, 106, 0) 100%),
    linear-gradient(334deg,
      rgba(83, 208, 236, 0.8) 0%,
      rgba(83, 208, 236, 0.2) 20%,
      rgba(138, 137, 190, 0) 35%),
    radial-gradient(109% 149% at 4% 98%,
      rgba(27, 49, 128, 1) 0%,
      rgba(27, 49, 128, 0) 100%),
    linear-gradient(142deg,
      rgba(78, 173, 235, 1) 19%,
      rgba(78, 173, 235, 0) 99%);

  --background-gradient-reversed: linear-gradient(142deg,
      rgba(78, 173, 235, 0) 99%,
      rgba(78, 173, 235, 1) 19%),
    radial-gradient(109% 149% at 4% 98%,
      rgba(27, 49, 128, 0) 100%,
      rgba(27, 49, 128, 1) 0%),
    linear-gradient(334deg,
      rgba(138, 137, 190, 0) 35%,
      rgba(83, 208, 236, 0.2) 20%,
      rgba(83, 208, 236, 0.8) 0%),
    radial-gradient(115% 123% at 0% -15%,
      rgba(231, 79, 106, 0) 100%,
      rgba(231, 79, 106, 0.2) 67%,
      rgba(231, 79, 106, 1) 1%),
    linear-gradient(208deg,
      rgba(240, 101, 42, 0) 64%,
      rgba(240, 88, 42, 0.2) 43%,
      rgba(240, 66, 42, 1) 7%),
    radial-gradient(64% 70% at 100% 0%,
      rgba(247, 214, 98, 0) 100%,
      rgba(247, 214, 98, 0.2) 52%,
      rgba(247, 214, 98, 0.8) 0%);
}


h1,
h2,
h3,
h4,
p {
  padding: 20px 10px;
  z-index: 1;
}

.wrapper {
  width: 90%;
  margin: auto;
}

body::-webkit-scrollbar {
  display: none !important;
}

.show-sm {
  display: none;
}

@media (max-width: 767px) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;
  }

  body::-webkit-scrollbar {
    all: unset;
  }
}

.App {
  padding: 0px 20px 0px 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: beige;
  z-index: -2;
  background: var(--background-gradient);
  min-height: 100vh;
}

button,
input {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.small-svg {
  z-index: 1;
  width: 200px;
}

.center {
  justify-content: center;
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: beige;
}

.logo {
  width: 290px;
  margin-bottom: -20px;
}

@media (max-width: 767px) {
  .logo {
    width: 100%;
    max-width: 290px;
    margin-bottom: -20px;
  }
}

.logo .line {
  stroke-width: 1.5;
  /* fill: url(#gradient); */
  stroke: beige;
}

.row {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
    justify-content: unset;
  }
}

.verticle {
  flex-direction: column;
}


ul {
  margin: 10px auto 0;
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
}

li {
  display: table-cell;
  position: relative;
}


@media (max-width: 767px) {
  ul {
    display: block;
    text-align: left;
    margin: 0;
    text-align: left;
    margin: 0px;
  }

  li {
    display: block;
  }
}


a {
  color: beige;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: beige;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

a:hover:after {
  width: 100%;
  left: 0;
}

@media screen and (max-height: 300px) {
  ul {
    margin-top: 40px;
  }
}

/* 
  coffee 
*/
*,
*::before,
*::after {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.info-block {
  width: 100%;
  text-align: left;
}

.info-block h3 {
  padding: 5px 0px 20px;
  font-size: 20px;
  text-align: left;
  color: rgb(10, 10, 35);
  color: beige;
}

.info-block p {
  font-weight: 300;
  padding: 0px;
  font-size: 16px;
  text-align: left;
  padding: 5px 0px;
  color: rgb(10, 10, 35);
  color: beige;
}

.tiny {
  font-weight: 200;
  font-size: 12px;
}

.no-link {
  padding: 0px;
  font-weight: 500;
}

.tiny:after, .no-link:after {
  transition: none;
  height: 0px;
  background: none;
}

.top {
  top: 20px;
}

.scroller {
  /* Adjust the transition timing as needed */
  position: fixed;
  right: 20px;
  display: flex;
  align-items: center;
  font-weight: 200;
  z-index: 3;
}

.scroller .arrow {
  width: 10px;
}

.bottom {
  bottom: 20px;
}

.social {
  width: 100px;
  border-radius: 50px;
}