/* variables */
:root {
  --primary: rgb(252, 192, 74);
  --secondary: rgb(69, 112, 180);
}

/* Norms */
form {
  width: max-content;
  height: max-content;
  font-size: 16pt;
  border-radius: 5px;
  overflow: hidden;
  font-weight: 200;
}

input {
  font-size: inherit;
  max-width: 500px;
  padding: 16px;
  margin-right: -1px;
  transition: padding 0.2s;
  border: 0;
  font-weight: 200;
}

input:focus {
  outline: none;
}

button {
  font-size: inherit;
  float: right;
  padding: 16px;
  margin-left: -1px;
  background: var(--primary);
  transition: padding 0.2s;
  border: 0;
  font-weight: 200;
}

button:hover {
  cursor: pointer;
  padding: 16px 24px;
}

button:hover~input {
  padding: 16px 0 16px 16px;
}

@media (max-width: 767px) {
  .response-input {
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    width: unset;
    height: unset;
  }

  input {
    width: 100%;
    display: block;
    max-width: unset;
    margin: 20px 0px 20px 0px;
    border-radius: 10px;
  }

  button {
    width: 100%;
    display: block;
    max-width: unset;
    border-radius: 10px;
  }
}