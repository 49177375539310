.tiles h3 {
  padding: 5px 0px;
  font-size: 20px;
  text-align: left;
  color: rgb(10, 10, 35);
  color: beige;
}

.tiles p {
  font-weight: 300;
  padding: 0px;
  font-size: 16px;
  text-align: left;
  padding: 5px 0px;
  color: rgb(10, 10, 35);
  color: beige;
}

.tiles p small {
  font-weight: 500;
  padding: 0px;
  font-size: 12px;
}

.img-wrapper {
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
}

.tiles img {
  width: 100%;
}

.tiles {
  width: 100%;
  columns: 300px;
  gap: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.tiles .tile-inner {
  width: 32%;
  break-inside: avoid;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
  transition: 0.5s;
  user-select: none;
  position: relative;
  overflow: hidden;
  background: var(--background-gradient);
  min-height: 360px;
}
.tile-inner a {
  min-height: 360px;
}

.tile-footer {
  bottom:0px;
  position: absolute;
}

@media (max-width: 967px) {
  .tiles .tile-inner {
    width: 48%;
  }
}


@media (max-width: 767px) {
  .tiles .tile-inner {
    width: 100%;
    min-height: unset;
  }
  .tile-inner a {  
    min-height: unset;
  }
  .tile-footer {
    position:initial;
  }
  
}



.tiles .tile-inner::before,
.tiles .tile-inner::after {
  content: "";
  font-weight: 700;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 100px;
  transition: 0.5s;
  cursor: pointer;
}

.tiles .tile-inner:hover {
  transform: scale(0.9) skew(-5deg);
}

.tiles .tile-inner:hover::after {
  background: var(--background-gradient-reversed);
  transform: scale(60);
  z-index: -1;
}

.tiles .tile-inner:active::after {
  transform: scale(60);
  z-index: -1;
}